import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "404bg.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    // Background image courtesy of Ian Cumming on Unsplash https://unsplash.com/@kingfishersnaps
    <Layout>
      <BackgroundImage
        Tag="section"
        className="notFound__wrapper"
        fluid={data.background.childImageSharp.fluid}
      >
        <div className="notFound__bg-blend">
          <SEO title="404: Not found" />
          <h1 className="notFound__title">404</h1>
          <p className="notFound__whoops">Whoops!</p>
          <p className="notFound__content">
            We couldn't find the page you were looking for.
          </p>
          <Link className="notFound__content-link" to="/">
            Back Home
          </Link>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default NotFoundPage
